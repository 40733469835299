import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import cls from "./form_dialog.module.scss";
import "./promotion.scss";

import { privacyUrl } from "../../const";
import LinkItem        from "../LinkItem/LinkItem";

import Phone from "../../assets/images/Landing/svg/phone.svg";
import CloseIcon from "@mui/icons-material/Close";


const IS_DEV = window.location.hostname === "localhost";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      overflow: "visible",
      marginTop: '-10px'
    },
    "& .MuiFormControl-root": {
      marginRight: '10px',
      paddingRight: '10px'
    }
  },
}));

export const PromotionDialog = ({
  isOpen,
  setIsOpen,
  phone,
  isShowPhoneNumber,
  setIsShowPhoneNumber,
  
  services,
  handleRedirect,
  handleOptionServices,
  region,
  userLocation,
}) => {

  const handleClose = () => {
    setIsShowPhoneNumber(false);
    setIsOpen(false);
  };
  const classes = useStyles();

  return (
    <Dialog
      style={{ marginTop: '50px'}}
      className={classes.root}
      open={isOpen}
      onClose={handleClose}
      maxWidth="md"
    >
      <IconButton
        id="contact-form_close-button"
        style={{
          width: "34px",
          height: "34px",
          position: "absolute",
          top: "-17px",
          right: "-17px",
          padding: "5px",
          background: "#fff",
          boxShadow: "0px 2px 3px #0000002b",
          zIndex: '10000',
          cursor: 'pointer'
        }}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      <div className={cls.modal_containerpromot}>

        {isShowPhoneNumber && (
          <a href={`tel:${phone}`} className={cls.phone_wrap}>
            <img src={Phone} alt="phone number" />
            <p className={cls.phone_number} style={{ color: "#19569c" }}>
              {phone}
            </p>
          </a>
        )}

        <section id="promotinal" className="prompop">
          <h3 style={{ lineHeight: '46px', padding: '0 0 20px' }}> <span>Don't delay your next projects!</span> <br/>Do them all now and save BIG!</h3>

          <section className="content">
            <div className="link_list_wrap" style={{ marginBottom: '0px' }}>
              <ul className="link_list promot">
                {services.map((itemService, index) => (
                  <LinkItem
                    key={index}
                    {...itemService}
                    onClick={handleRedirect}
                    opeFormOptions={handleOptionServices}
                    geoData={region}
                    userLocation={userLocation}
                  />
                ))}
              </ul>
            </div>
          </section>

          <div className="txtContainer">
            <p style={{ padding: '29px 0px 0px' }} >
              All discounts are applicable only when all projects are booked at the same time (can not be combined with any other promotions/offers)
            </p>
            <div style={{
              textAlign: 'center',
              marginBottom: '10px',
              color: '#333',
              fontSize: '13px',
            }}>   
            {/* <a  href={'/reviews'}>
              AGM Renovations Reviews
            </a> */}
            </div>
          </div>
        </section>

      </div>
    </Dialog>
  );
};
