import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  IconButton,
  TextField,
  Typography,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import cls from "../../assets/Styles/stylesRefForm.scss";
import { green } from "@material-ui/core/colors";

import { privacyUrl } from "../../const";
import CloseIcon from "@mui/icons-material/Close";
import Phone from "../../assets/images/Landing/svg/phone.svg";

const IS_DEV = window.location.hostname === "localhost";

// Basement
// Bathroom
// Kitchen
// Flooring
// Painting
// Roofing
// Drywall Repair
// Window Coverings
// Closet Organizers
// Windows
// Roofing Insurance Claim

const projectTypes = [
  {
    value: "Basement",
    title: "Basement Renovations",
    link: "https://agmbasements.com/",
    availableFor: ["gta"],
  },
  {
    value: "Bathroom",
    title: "Bathroom Renovations",
    link: "https://bathroomrenovations.com/",
  },
  {
    value: "Kitchen",
    title: "Kitchen Renovations",
    link: "https://agmkitchens.com/",
  },
  {
    value: "Roofing",
    title: "Roofing",
    // availableFor: ["gta"],
    link: "https://agmroofs.com/",
  },
  {
    value: "Flooring",
    title: "Flooring/Stairs",
    visible: 'mobile',
    availableFor: ["non_gta"],
    link:  "https://agmflooring.com/",
  },
  {
    value: "Painting",
    title: "Painting",
    visible: 'mobile',
    availableFor: ["non_gta"],
    link:  "https://agmpainting.com/",
  },
  {
    value: "Closet Organizers",
    title: "Custom Closets",
    visible: 'mobile',
    availableFor: ["gta"],
    link: "https://agmkitchens.com/",
    option: 'closetorg',
  },
  {
    value: "Windows",
    title: "Windows & Doors",
    visible: 'mobile',
    availableFor: ["gta"],
    link: "https://agmwindows-doors.com/",
  },
  {
    value: "Window Coverings",
    title: "Blinds",
    visible: 'mobile',
    availableFor: ["gta"],
    link: "https://agmkitchens.com/",
    option: 'blinds',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
  

    "& .MuiPaper-root": {
      overflow: "visible",
      marginTop: "-10px",
    },
    "& .MuiFormControl-root": {
      marginRight: "10px",
      paddingRight: "10px",
    },

    [theme.breakpoints.down("sm")]: {
      width: "270px",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  form_failed: {
    margin: "5px",
    color: "red",
    "& .MuiInputBase-root": {
      color: "red",
    },
  },

  form_success: {
    margin: "5px",
  },
  menuStyle: {
    border: "1px solid black",
    borderRadius: "5%",
    backgroundColor: "lightgrey",
  },
}));

const setServiceValue = (service, services) =>
  !service || (!service?.value ? services[0]?.value : service.value);

export const MainForm = () => {


  const { enqueueSnackbar } = useSnackbar();
  const mobile = window.innerWidth < 650;
  const classes = useStyles();
  const [isDataSent, setSentData] = useState(false);
  const [isSubmited, setSubmited] = useState(false);

  // setServiceValue('service', projectTypes)

  const { errors, touched, values, handleChange, handleSubmit, isSubmitting } = useFormik(
    {
      enableReinitialize: true,
      initialValues: {
        FirstName: "",
        LastName: "",
        city: "",
        Email: "",
        phone: "",
        Renovation_Type__c: '',
      },
      validationSchema: Yup.object().shape({
        FirstName: Yup.string().required(),
        LastName: Yup.string().required(),
        city: Yup.string().required(),
        Email: Yup.string().email().required(),
        phone: Yup.string().required(),
        Renovation_Type__c: Yup.string().required(),
      }),

      // setServiceValue(service, services),
      // initialValues: {
      //   FirstName: "",
      //   LastName: "",
      //   city: "",
      //   Email: "",
      //   phone: "",
      //   Renovation_Type__c: '',
      // },
      // validationSchema: Yup.object().shape({
      //   FirstName: Yup.string().required(),
      //   LastName: Yup.string().required(),
      //   city: Yup.string(),
      //   Email: Yup.string().email().required(),
      //   phone: Yup.string(),
      //   Renovation_Type__c: Yup.string().required(),
      // }),

      onSubmit: async (values, { resetForm }) => {
        if (IS_DEV) console.log("VALUES", { ...values });

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const body = JSON.stringify({
          ...values,
          LeadSource: "",
          LeadOrigin: "DRMG Flyers",
          Company: "",
        });

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          redirect: "follow",
          mode: "no-cors",
          body
        };

        // console.log('body', JSON.parse(body) )
        setSubmited(true);

        const resp = await new Promise((resolve) => {
          fetch(
            "https://agmrenovations.my.salesforce-sites.com/services/apexrest/createlead",
            requestOptions
          )
            .then((response) => response.text()).then((resp) => (resolve(resp)))
            .catch((error) =>
              enqueueSnackbar("Something wrong. Please try again later.", {
                variant: "error",
              })
            );
        });

        // if (IS_DEV) 
        // console.log("resp", resp);

        // TODO show success text here (copy from bathroom website) and close the form
        setSentData(true);
        setSubmited(false);

        resetForm();
        setTimeout(() => {
          setSentData(false);
        }, 4000);

      },
    });

    


  return (
    <>
    <div className="wrapperModal">
      <div className="modalContainerRef">


        <div
        className='titleCont'
          style={{
            display: "flex",
            margin: "10px 40px 20px 40px",
            justifyContent: 'space-between',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {/* <img
            style={{ width: "220px" }}
            src="/static/media/logo.107dfbad.svg"
          /> */}
          <h2 style={{
            color: '#555',
            userSelect: 'none',
            textShadow: '1px 1px 3px rgba(0,0,0,0.2)',
            fontWeight: '200',
          }}>Get Started</h2>
          <span style={{
            color: '#555',
            fontWeight: '600',
          }}>Enter your info below to get started!</span>
        </div>


        <div className={classes.root} style={{ margin: "auto" }}>
          <div className={cls.modal_container}>
            <form
              id="contact-form"
              className={cls.form}
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                className={cls.inputs_col}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div>
                  <TextField
                    name="FirstName"
                    label="First Name *"
                    variant="outlined"
                    type="text"
                    value={values.FirstName}
                    onChange={handleChange}
                    className={
                      Boolean(touched.FirstName && errors.FirstName)
                        ? classes.form_failed
                        : classes.form_success
                    }
                    // InputProps={{ disableUnderline: true }}
                    error={Boolean(touched.FirstName && errors.FirstName)}
                    InputLabelProps={{
                      style: {
                        marginLeft: "10px",
                      },
                    }}
                    style={{
                      // width: `${mobile ? "270px" : "466px"}`,
                      paddingLeft: "10px",
                    }}
                  />            
                  
                </div>
                <div>
                  <TextField
                    name="LastName"
                    label="Last Name *"
                    variant="outlined"
                    type="text"
                    value={values.LastName}
                    onChange={handleChange}
                    className={
                      Boolean(touched.LastName && errors.LastName)
                        ? classes.form_failed
                        : classes.form_success
                    }
                    // InputProps={{ disableUnderline: true }}
                    error={Boolean(touched.LastName && errors.LastName)}
                    InputLabelProps={{
                      style: {
                        marginLeft: "10px",
                      },
                    }}
                    style={{
                      // width: `${mobile ? "270px" : "466px"}`,
                      paddingLeft: "10px",
                    }}
                  />
                </div>

                <TextField
                  name="phone"
                  variant="outlined"
                  label="Contact Number*"
                  type="number"
                  value={values.phone}
                  onChange={(e) => {
                    if (e.target.value.toString().length <= 10) {
                      handleChange(e);
                    }
                  }}
                  className={
                    Boolean(touched.phone && errors.phone)
                      ? classes.form_failed
                      : classes.form_success
                  }
                  // InputProps={{ disableUnderline: true }} 
                  error={Boolean(touched.phone && errors.phone)}
                  InputLabelProps={{
                    style: {
                      marginLeft: "10px",
                      // display: values.phone ? "none" : "block",
                      // top: values.phone ? "-13px" : "-2px",
                      // fontSize: values.phone ? "small" : "large",
                      // color: values.phone ? "gray" : "none",
                      // transition: values.phone ? "180ms" : "none",
                    },
                  }}
                  style={{
                    // width: `${mobile ? "270px" : "466px"}`,
                    paddingLeft: "10px",
                  }}
                />
                <TextField
                  name="Email"
                  label="Email Address*"
                  variant="outlined"
                  type="text"
                  value={values.Email}
                  onChange={handleChange}
                  className={
                    Boolean(touched.Email && errors.Email)
                      ? classes.form_failed
                      : classes.form_success
                  }
                  error={Boolean(touched.Email && errors.Email)}
                  InputLabelProps={{
                    style: {
                      marginLeft: "10px",
                    },
                  }}
                  style={{
                    // width: `${mobile ? "270px" : "466px"}`,
                    paddingLeft: "10px",
                  }}
                />
                <TextField
                  name="city"
                  label="City*"
                  variant="outlined"
                  type="text"
                  value={values.city}
                  onChange={handleChange}
                  className={
                    Boolean(touched.city && errors.city)
                      ? classes.form_failed
                      : classes.form_success
                  }
                  error={Boolean(touched.city && errors.city)}
                  InputLabelProps={{
                    style: {
                      marginLeft: "10px",
                    },
                  }}
                  style={{
                    // width: `${mobile ? "270px" : "466px"}`,
                    paddingLeft: "10px",
                  }}
                />
            {/* <TextField
                  select
                  id="location_select"
                  variant="outlined"
              name="Renovation_Type__c"
              label=" __Type of project"
              value={values.Renovation_Type__c}
              // defaultValue={values.Renovation_Type__c}
              onChange={handleChange}
              error={Boolean(
                touched.Renovation_Type__c && errors.Renovation_Type__c
              )}
              SelectProps={{ native: true }}
              className={
                Boolean(touched.location && errors.location)
                  ? classes.form_failed
                  : classes.form_success
              }
              style={{
                width: `${mobile ? "270px" : "466px"}`,
                paddingLeft: "10px",
              }}
              // SelectProps={{
              //   renderValue: (value) => value,
              // }}
            >
              {projectTypes.map((service, index) => {
                if (!service) return null;
                return (
                  <option key={index} value={service.value}>
                    {service.title}
                  </option>
                );
              })}
            </TextField> */}


            <FormControl style={{ marginLeft: `14px`, marginTop: '6px' }} className='selectButt' variant="outlined" >
              <InputLabel id="demo-simple-select-outlined-label">Renovation Type</InputLabel>
              <Select
                value={values.Renovation_Type__c}
                onChange={handleChange}
                label="Renovation Type"
                name="Renovation_Type__c"
                error={Boolean(
                  touched.Renovation_Type__c && errors.Renovation_Type__c
                )}
                // style={{
                //   width: `${mobile ? "250px" : "446px"}`,
                // }}
              >

                {projectTypes.map((service, index) => {
                  if (!service) return null;
                  return (
                    <MenuItem key={index} value={service.value}>{service.title}</MenuItem>
                  );
                })}

                <MenuItem value={null}></MenuItem>

              </Select>
            </FormControl>

                {/* <TextField
                  select
                  id="location_select"
                  variant="outlined"
                  value={values.location}
                  error={Boolean(touched.location && errors.location)}
                  onChange={(e) => {
                    if (locations.some((item) => item.value === "Location")) {
                      locations.shift();
                    }
                    handleChange(e);
                  }}
                  name="location"
                  SelectProps={{
                    native: true,
                  }}
                  className={
                    Boolean(touched.location && errors.location)
                      ? classes.form_failed
                      : classes.form_success
                  }
                  // InputProps={{
                  //   disableUnderline: true,
                  // }}
                  style={{
                    width: `${mobile ? "270px" : "466px"}`,
                    paddingLeft: "10px",
                  }}
                >
                  {locations.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField> */}
              </div>

              <Button
                id="contact-form_submit-button"
                type="submit"
                className={isSubmited ? "submit process" : "submit"}
                disabled={isSubmited}
                style={{
                  // width: `${mobile ? "270px" : "446px"}`,
                  margin: mobile ? "10px 5px 0 0" : "15px 5px 0 0",
                  height: mobile ? "40px" : "50px",
                }}
              >
                {isSubmited ? ( <CircularProgress style={{ color: '#fff' }} />) : ('Submit')}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>

   <Dialog
        className={classes.root}
        open={isDataSent}
        onClose={()=>{ setSentData(false); }}
        maxWidth="md"
      >
        <IconButton
          style={{
            position: "absolute",
            top: "-17px",
            right: "-17px",
            padding: "5px",
            background: "#fff",
            boxShadow: "0px 2px 3px #0000002b",
            width: "34px",
            height: "34px"
          }}
          onClick={()=>{ setSentData(false); }}
        >
          <CloseIcon />
        </IconButton>
        <div style={{ padding: '20px', textAlign: 'center' }} className={cls.modal_container}>
          <h1 className={cls.title_success}>Thank You</h1>
          <br />
          <p>Someone from the AGM team will reach out shortly.</p>
        </div>
      </Dialog> 

    </>
  );
};
