import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@mui/icons-material/Close";
import { privacyUrl } from "../../const";
import Phone from "../../assets/images/Landing/svg/phone.svg";
import Poster from "./poster.jpg";

import cls from "./form_dialog.module.scss";
import "./promotion.scss";

const IS_DEV = window.location.hostname === "localhost";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      overflow: "visible",
      marginTop: '-10px'
    },
    "& .MuiFormControl-root": {
      marginRight: '10px',
      paddingRight: '10px'
    }
  },
}));

export const OptionsDialog = ({
  isOpen,
  setIsOpen,
  children,
}) => {

  const handleClose = () => {
    setIsOpen(false);
  };
  const classes = useStyles();

  return (
    <Dialog
      style={{ marginTop: '50px'}}
      className={classes.root}
      open={isOpen}
      onClose={handleClose}
      maxWidth="md"
    >
      <IconButton
        id="contact-form_close-button"
        style={{
          width: "34px",
          height: "34px",
          position: "absolute",
          top: "-17px",
          right: "-17px",
          padding: "5px",
          background: "#fff",
          boxShadow: "0px 2px 3px #0000002b",
          zIndex: '10000',
          cursor: 'pointer'
        }}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      <div className={cls.modal_container}>
        {children}
      </div>
    </Dialog>
  );
};
