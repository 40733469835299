import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import AppEvent from "./AppEvent";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { SnackbarProvider } from "notistack";
// import { ReferralProgram } from "./components/ReferralProgram/";
// import { Reviews } from "./components/Reviews/";
// import ReferralAgents from "./components/ReferralAgents/ReferralAgents";

{
  /* <Suspense fallback={<></>}>
        <Routes>
          <Route exact path="/referralprogram" component={ReferralProgram} />
          <Route exact path="/" component={MainPage} />
        </Routes>
      </Suspense> */
}
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <SnackbarProvider domRoot={document.getElementById("react-notification")}>
        <Routes>
          <Route path="*" element={<App />} />
          <Route exact path="/events" element={<AppEvent/>} />
       

          {/* <Route exact path="reviews" element={<Reviews/>} /> */}
          {/* <Route exact path="referralprogram/rainbow" element={<ReferralProgram type={'rainbow'} />} />
          <Route exact path="referralprogram/rina"    element={<ReferralProgram type={'rina'}    />} />
          <Route exact path="referralprogram/sales"   element={<ReferralProgram type={'sales'}   />} /> */}
          {/* <Route
            exact
            path="/referralprogram/agent"
            element={<ReferralAgents />}
          /> */}
        </Routes>
      </SnackbarProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
